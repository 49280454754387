@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.primary {
  color: #33ccff;
}

.form-participant-scroll {
  height: auto;
  max-height: 100px;
  overflow: auto;
  scrollbar-width: thin;
}

.event-dialog-scroll {
  scrollbar-width: thin;
}

.event-participant-scroll {
  height: auto;
  max-height: 200px;
  overflow: auto;
  scrollbar-width: thin;
}

#chat-page-parent-div {
  color: #000000b8;
}

body {
  font-family: "Lato", sans-serif;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
}

html {
  background-color: #E0FAFF;
}

#root {
  max-width: 1600px;
  background-color: white;
  height: -webkit-fill-available;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width:1281px) {
  #root {
    margin: 0rem auto;
  }
}

.textSideBorder {
  display: flex;
  flex-direction: row;
}

.text-with-lines {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.text-with-lines::before,
.text-with-lines::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d1d5db;
  margin: 0 1rem;
}

.textSideBorder::before,
.textSideBorder::after {
  content: "";
  border-bottom: 1px solid #8080804d;

  margin: auto;
  width: 96%;
}

.textSideBorder::before {
  margin-right: 10px;
}

.textSideBorder::after {
  margin-left: 10px;
}

.bg-primary {
  background-color: #33ccff;
}

.border-botom-primary {
  border-bottom: 2px solid #33ccff;
}

.border-left-primary {
  border-left: 3px solid #33ccff;
}

.lightBlack {
  color: #333333;
}

.bgGray {
  background-color: #828282;
}

.textGray {
  color: #828282 !important;
}

.textRed {
  color: #ff7d7d;
}

.ArrowIconSize {
  font-size: 20px;
}

.scrollCss::-webkit-scrollbar {
  display: none;
}

.css-8t49rw-MuiStepConnector-line {
  min-height: 10px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #33ccff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #33ccff !important;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 690px !important;
} */

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #33ccff !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #33ccff !important;
}

.css-h4y409-MuiList-root {
  padding-top: 0px !important;
}

.css-h4y409-MuiList-root {
  padding-bottom: 0px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  height: 5px;
  width: 4px;
}

.hide-arrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 
::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
::-webkit-scrollbar-thumb {
  background: #888;
  visibility: hidden;
}

#scrollableDiv::-webkit-scrollbar {
  height: 5px;
  width: 8px;
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
}

#roomListScroll::-webkit-scrollbar {
  height: 5px;
  width: 4px;
}

#roomListScroll::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
}

.textAreaScroll::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
}

.audio-recorder-options:last-child {
  display: none;
}

#contact-info::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}

#contact-info::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
}

/* .audio-recorder.recording .audio-recorder-mic {
  display: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Applink {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.yarl__container,
.yarl__thumbnails_container {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.yarl__thumbnails_vignette {
  background: transparent !important;
}

/* 
@media screen and (min-width: 1441px) {
  body {
    max-width: 1600px;
    width: 100%;
    margin: auto;
  }
} */

/* .reactEasyCrop_Contain{
  /* left:50px!important 
} */

/* .wallpaper-image{

  background-image: url("https://storage.googleapis.com/comon-bucket/6458d2c3c201f36fe372c9ca/645a1cd859c4c4ae851784f0/16837259585-wallpaper.jpg")!important;
  height: 100vh;
}

.wallpaper-image::before{
  background-image: url("https://storage.googleapis.com/comon-bucket/6458d2c3c201f36fe372c9ca/645a1cd859c4c4ae851784f0/16837259585-wallpaper.jpg")!important;

  opacity: 0.5;
  height: 100vh;
} */
.reactEasyCrop_Contain {
  /* left:50px!important */
}

.mentions__suggestions {
  width: 100% !important;
}

.mentions__suggestions__list {
  background-color: #E0FAFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 10pt;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
}

.mentions__suggestions__item {
  padding: 5px 15px;
}

.mentions__suggestions__item--focused {
  background-color: #ffffff;
}

textarea:focus {

  outline: none !important;
}

textarea::placeholder {
  position: absolute;
  top: 12px;
  /* Placeholder text color */
}

#message-input {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
  border: 1px solid rgb(158, 157, 157);
  border-radius: 5px;
  padding-left: 8px;
  min-height: 50px !important;
  top: -7px !important;
  overflow-y: scroll !important;
  max-height: 170px;
  /*  margin-top: .3rem !important; */
  padding-top: .8rem !important;
}

#password-input {
  padding: 8px 10px !important;
}

#login .MuiInputLabel-root {
  margin-top: -8px !important;
}


#audio audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #b9ecf6;
  color: #b9ecf6;
}

.xxl-h-67px {
  height: 70vh;
}

@media(min-width:1280px)and (max-width:1300px) {
  .xxl-h-67px {
    height: 67vh;
  }
}


@media(min-width:1750px) {
  .xxl-h-67px {
    height: 67vh;
  }

  .large-size {
    height: 65vh;
  }
}

@media (max-width: 1024px) {
  .large-size {
    height: 56vh;
  }
}

.infinite-scroll-component {
  overflow-x: hidden;
}


#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-picker-dropdown {
  z-index: 1000000 !important;
}

.YourHighlightClass {
  background-color: yellow;
  font-weight: bold;
}