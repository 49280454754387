@tailwind base;
@tailwind components;
@tailwind utilities;


/* .loading
{
  width: auto;
  height: 25px;
  margin: 0px auto;
  position: relative;
}
.loading span
{ 
  width: 4px;
  display: block;
  position: absolute;
  bottom: 7px;
  height: 5px;
  background-color: #9b59b6;
  animation: waving 1.5s infinite ease-in-out;
}
.loading span:nth-of-type(2){left:11px; animation-delay: .2s;}
.loading span:nth-of-type(3){left:22px;animation-delay: .4s;}
.loading span:nth-of-type(4){left:33px;animation-delay: .6s;}
.loading span:last-of-type(16){left:44px;animation-delay: .8s;}

@keyframes waving 
{
  0%, 50%, 100%
  { 
    height: 5px;
    transform: translatey(0);
    background-color: #9b9b9b; }
   25%
  { height: 35px;
    transform: translatey(15px); 
    background-color: #33CCFF 
  }
} */
.loading {
    width: 260px;
    padding: 9px 0;
    /* height: 200px;
    margin: 50px auto; */
  }
 .loading span {
    display: inline-block;
    background-color: #dfdfdf;
    width: 4px;
    margin:2px 4px;
    height: 8px;
    animation: wave 2s linear infinite;
  }
  .loading .a1 {
    animation-delay: 0s;
  }
  .loading .a2 {
    animation-delay: .2s;
  }
  .loading .a3 {
    animation-delay: .4s;
  }
  .loading .a4 {
    animation-delay: .6s;
  }
  .loading .a5 {
    animation-delay: .8s;
  }
  @keyframes wave {
    0%, 50%{
      transform: scaleY(1);
    }
    25% {
      transform: scaleY(4);
      background-color: #33CCFF;
    }
  }


#arrow .MuiPopover-paper{
  left: 10px !important;
  right:10px !important;
  width: 28.8%;
  top:200px !important
}


.child{height: 405px;
}
@media(max-width:1560px){
  .child{
    overflow-y: scroll !important;
    overflow-x: hidden;
    max-height: 100%;
 /*    height: 180px !important;
 */  }
}


.bottom-space{
  /* bottom: -9px; */
  bottom: 0px;
}

@media(min-width:1450px){

  .bottom-space{
    /* bottom: -12px; */
    /* bottom -12px issue when choose media in chat room message box cutting */

    bottom: 0px;
  }
}